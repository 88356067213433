
export const apiBaseUrl = process.env.REACT_APP_USER_MANAGEMENT_SERVICE_URL;
export const reactApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

const config = {
  apiBaseUrl,
  reactApiKey, 
};

export default config;
