export default {
  deliveryDate: "تاريخ التوصيل",
  deliveryDateIsRequired: "تاريخ التوصيل مطلوب",
  deliveryFeeValueIsNotPositive: "قيمة رسوم التوصيل يجب أن تكون رقم إيجابي",
  deliveryFeeValueIsRequired: "قيمة رسوم التوصيل مطلوبة",
  deliveryFees: "رسوم التوصيل",
  deliveryFeesIsRequired: "رسوم التوصيل مطلوبة",
  deliveryFeesPlaceHolder: "2 د.أ",
  dropOffFieldToolTip:
    "بعد تقديم الطلب، يتعين على المستلم تأكيد موقعه عبر واتساب.",
  dropOffLocation: "موقع التوصيل",
  dropOffLocationIsRequired: "موقع التوصيل مطلوب",
  editOrder: "تعديل الطلب",
  goodsCategory: "فئة البضائع",
  goodsCategoryInitialValue: "اختر فئة البضائع",
  goodsCategoryIsRequired: "فئة البضائع مطلوبة",
  locationInitialValue: "اختر الموقع",
  notPaid: "غير مدفوعة",
  notPaidOptionToolTip:
    "هذا الخيار إذا كانت رسوم التوصيل ستدفع من قبل المستلم.",
  note: "اكتب هنا",
  notes: "ملاحظات",
  notificationResendedSuccessfully:"تم اعادة ارسال رسالة الواتس اب ",
  optional: "اختياري",
  orderFees: "قيمة الطلب",
  orderFeesIsNotPositive: "يجب أن تكون رسوم الطلب رقم إيجابي",
  orderFeesIsRequired: "قيمة الطلب مطلوبة",
  orderInformation: "معلومات الطلب",
  paid: "مدفوعة",
  paidOptionToolTip: "هذا الخيار إذا كانت رسوم التوصيل ستدفع من قبل المرسل.",
  pickupDate: "تاريخ الاستلام",
  pickupDateIsRequired: "تاريخ الاستلام مطلوب",
  pickupLocation: "موقع الالتقاط",
  pickupLocationIsRequired: "موقع الاستلام مطلوب",
  pickupTime: "وقت الاستلام",
  pickupTimeIsRequired: "وقت الاستلام مطلوب",
  pickupTimeSubText: "(يمكنك اختيار فترات زمنية متعددة)",
  placeOrder: "تقديم الطلب",
  preferredDropOffTime: "الوقت المفضل للتوصيل",
  preferredDropOffTimeIsRequired: "وقت التوصيل المفضل مطلوب",
  preferredVehicleInitialValue: "اختر نوع السيارة",
  preferredVehicleType: "نوع السيارة المفضل",
  preferredVehicleTypeIsRequired: "نوع المركبة المفضل مطلوب",
  publishOrder: "نشر الطلب",
  quantity: "الكمية",
  quantityInitialValue: "اختر الكمية",
  quantityIsNotPositive: "الكمية يجب أن تكون رقم إيجابي",
  quantityIsRequired: "الكمية مطلوبة",
  receiverFullName: "اسم المستلم الكامل",
  receiverFullNameIsRequired: "الاسم الكامل للمستلم مطلوب",
  receiverPhoneNumber: "رقم هاتف المستلم",
  receiverPhoneNumberIsRequired: "رقم هاتف المستلم مطلوب",
  receiverWhatsApp:"واتساب المستلم",
  resendWhatsAppMessages:"اعادة ارسال رسالة الواتس اب ",
  save: "حفظ",
  saveAsDraft: "حفظ كمسودة",
  saveChanges: "حفظ التعديلات",
  scheduleShipmentError:
    "يجب أن تكون تواريخ الاستلام والتوصيل متطابقة أو  أيامًا متتالية.",
  secondPhoneNumber: "الرقم الثاني للهاتف",
  shipmentDetails: "تفاصيل الشحن",
  shipmentSchedule: "تاريخ ووقت التوصيل",
  shipmentScheduleIsRequired: "تاريخ ووقت التوصيل مطلوبان",
  shipmentScheduleSubtitle: "يرجى اختيار مواعيد وتواريخ استلام وتوصيل مفضلة",
  size: "الحجم",
  sizeInitialValue: "اختر الحجم",
  sizeIsRequired: "الحجم مطلوب",
  theDriverhasNoConversationForThisOrder:"السائق لا يوجد لديه محادثة لهذا الطلب ",
  viewOrder: "عرض الطلب",
  waitingForLocationConfirmation: "بانتظار تأكيد الموقع",
};
