import addressBookIcon from "assets/address-book.svg";
import calender from "assets/calender.svg";
import myContactsIcon from "assets/contacts.svg";
import dashboardIcon from "assets/dashboard-icon-unselected.svg";
import dropDownLocation from "assets/dropdown-location.svg";
import orderManagementIcon from "assets/orders-management-unselected.svg";
import phoneFiltersIcon from "assets/show-filters-icon.svg";

export const ORDER_CREATION_TYPE = (t) => [
  {
    id: 0,
    label: t("regularOrder"),
    link: "./manage-orders/regular",
    value: "Regular Order",
  },
  // {
  //   id: 1,
  //   label: t("expeditedOrder"),
  //   link: "./manage-orders/expedited",
  //   value: "Expedited Order",
  // },
];

export const COLUMNS_NAMES = (t, isReversed) => {
  const columns = [
    {
      id: 0,
      name: t("orderId"),
    },
    {
      id: 1,
      name: t("receiverName"),
    },
    {
      id: 2,
      name: t("phoneNo"),
    },
    {
      id: 3,
      name: t("createdDate"),
    },
    {
      id: 4,
      name: t("pickUpColumnName"),
    },
    {
      id: 5,
      name: t("dropOffColumnName"),
    },
    {
      id: 6,
      name: t("status"),
    },
    {
      id: 7,
      name: t("action"),
    },
  ];

  return isReversed ? columns.reverse() : columns;
};

export const statusLabels = (t, props = {}, handelActionStatus) => {
  const deleteAction = {
    id: 1,
    label: t("delete"),
    onClick: () => {
      handelActionStatus({
        openPopup: true,
        orderId: props?.orderId,
        popupType: "delete",
      });
    },
    tag: "div",
  };

  const editAction = {
    id: 2,
    label: t(t("edit")),
    link: `./manage-orders/${props?.orderId}_${props?.status}`,
    onClick: () => {},
    tag: "link",
  };

  const trackAction = {
    id: 3,
    label: t(t("trackOrder")),
    link: `./track-order/${props?.orderId}`,
    onClick: () => {},
    tag: "link",
  };

  const cancelAction = {
    id: 4,
    label: t("cancel"),
    onClick: () => {
      handelActionStatus({
        openPopup: true,
        orderId: props?.orderId,
        popupType: "cancel",
      });
    },
    tag: "div",
  };

  const viewAction = {
    id: 5,
    label: t("view"),
    link: `./manage-orders/${props?.orderId}_${props?.status}`,
    onClick: () => {},
    tag: "link",
  };

  return [
    { actions: [], label: t("all"), value: 0 },
    {
      actions: [editAction, deleteAction],
      color: "169, 169, 169",
      label: t("draft"),
      value: 1,
    },
    {
      actions: [editAction, cancelAction],
      color: "205, 96, 56",
      label: t("incompleteData"),
      value: 2,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "30, 144, 255",
      label: t("awaitingSelection"),
      value: 3,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "217, 136, 14",
      label: t("pendingPickUp"),
      value: 4,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "238, 72, 152",
      label: t("pickUpStarted"),
      value: 5,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "255, 215, 0",
      label: t("arrivedAtPickUp"),
      value: 6,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "0, 206, 209",
      label: t("orderCollected"),
      value: 7,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "255, 180, 167",
      label: t("orderOutForDelivery"),
      value: 8,
    },
    {
      actions: [editAction, trackAction],
      color: "70, 130, 180",
      label: t("arrivedAtDropOff"),
      value: 9,
    },
    {
      actions: [editAction, trackAction],
      color: "52, 168, 83",
      label: t("delivered"),
      value: 10,
    },
    {
      actions: [viewAction, trackAction],
      color: "215, 19, 20",
      label: t("cancelled"),
      value: 11,
    },
    {
      actions: [editAction, trackAction],
      color: "0, 57, 143",
      label: t("rejected"),
      value: 12,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "255, 140, 0",
      label: t("onHold"),
      value: 13,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "69, 90, 100",
      label: t("reschaduled"),
      value: 14,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "138, 43, 226",
      label: t("returned"),
      value: 15,
    },
    {
      actions: [cancelAction, trackAction],
      color: "178, 34, 34",
      label: t("failed"),
      value: 16,
    },
    {
      actions: [editAction, cancelAction, trackAction],
      color: "159, 88, 4",
      label: t("requestDriverChange"),
      value: 17,
    },
    {
      actions: [cancelAction, trackAction],
      color: "42, 100, 51",
      label: t("driverChangeApproved"),
      value: 18,
    },
  ];
};

export const ORDER_SIDEBAR_LINKS = [
  {
    icon: dashboardIcon,
    id: 0,
    label: "dashboard",
    link: "",
  },
  {
    icon: orderManagementIcon,
    id: 1,
    label: "ordersManagement",
    link: "./order-management",
  },
  {
    icon: dropDownLocation,
    id: 2,
    label: "myLocations",
    link: "./my-locations",
  },
  {
    icon: myContactsIcon,
    id: 3,
    label: "myContacts",
    link: "./my-contacts",
  },
  {
    icon: addressBookIcon,
    id: 4,
    label: "addressBook",
    link: "./address-book",
  },
];

export const ORDER_TYPES = (t) => [
  {
    label: t("all"),
    value: null,
  },
  {
    label: t("expedited"),
    value: true,
  },
  {
    label: t("regular"),
    value: false,
  },
];

export const PHONE_FILTERS_TYPES = [
  {
    icon: phoneFiltersIcon,
    id: 0,
    value: "filters",
  },
  {
    icon: calender,
    id: 1,
    value: "calender",
  },
];

export const TABLE_SKELETON_CELLS_SIZES = [
  { height: 30, width: 30 },
  { height: 30, width: 90 },
  { height: 30, width: 100 },
  { height: 30, width: 80 },
  { height: 30, width: 80 },
  { height: 30, width: 80 },
  { height: 30, width: 100 },
  { height: 30, width: 30 },
];

export const ORDER_STATUS = {
  ARRIVED_AT_DROPOFF: 9,
  ARRIVED_AT_PICKUP: 6,
  AWAITING_SELECTION: 3,
  CANCELLED: 11,
  DELIVERED: 10,
  DRAFT: 1,
  DRIVER_CHANGE_APPROVED: 18,
  FAILED: 16,
  INCOMPLETE_DATA: 2,
  ON_HOLD: 13,
  ORDER_COLLECTED: 7,
  ORDER_OUT_FOR_DELIVERY: 8,
  PENDING_PICKUP: 4,
  PICKUP_STARTED: 5,
  REJECTED: 12,
  REQUEST_DRIVER_CHANGE: 17,
  RESCHEDULED: 14,
  RETURNED: 15,
};
