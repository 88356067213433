import BaseService from "./base-service";

/**
 * OrderService handles order-related operations such as fetching, placing, editing, and canceling orders.
 *
 * @class OrderService
 * @extends {BaseService}
 */
export default class OrderService extends BaseService {
  path = "order-mgmt/sender";

  /**
   * Retrieves a list of orders with pagination, search, and filtering options.
   *
   * @param {number}  page             The page number for pagination.
   * @param {number}  pageSize         The number of orders per page.
   * @param {string}  searchQueryValue The search query to filter orders.
   * @param {string}  orderStatus      The status to filter orders.
   * @param {boolean} isExpedited      Whether the order is expedited.
   * @param {string}  dateValue        The exact date to filter orders.
   * @param {string}  userId           The Id of the logged user.
   *
   * @returns {Promise}
   */
  getOrders = async (
    page,
    pageSize,
    searchQueryValue,
    orderStatus,
    // isExpedited,
    dateValue,
    userId
  ) => {
    const pageQuery = page > 1 ? `&PageNumber=${page}` : "";
    const pageSizeQuery =
      pageSize > 0 && !isNaN(+pageSize) ? `&PageSize=${pageSize}` : "";
    const searchQuery = searchQueryValue
      ? `&SearchQuery=${searchQueryValue}`
      : "";
    const statusQuery = orderStatus ? `&Status=${orderStatus}` : "";
    // const isExpeditedQuery =
    //   isExpedited !== null ? `&IsExpedited=${isExpedited}` : "";
    const exactDateQuery = dateValue ? `&ExactDate=${dateValue}` : "";

    // old query   const query = `?senderId=${userId}${pageQuery}${pageSizeQuery}${searchQuery}${statusQuery}${isExpeditedQuery}${exactDateQuery}&SortDescending=true`;

    const query = `?senderId=${userId}${pageQuery}${pageSizeQuery}${searchQuery}${statusQuery}${exactDateQuery}&SortDescending=true`;

    return await this.get(`${this.path}/orders`, query);
  };

  /**
   * Retrieves a specific order by its ID.
   *
   * @param {string} orderId The ID of the order.
   * @returns {Promise}
   */
  getOrder = async (orderId) => {
    const query = `?orderId=${orderId}`;
    return await this.get(`${this.path}/order`, query);
  };

  /**
   * Retrieves the logs of a specific order.
   *
   * @param {string} orderId The ID of the order.
   *
   * @returns {Promise}
   */
  getOrderLogs = async (orderId) => {
    const query = `?orderId=${orderId}`;
    return await this.get("order-mgmt/order/logs", query);
  };

  /**
   * Places a new order.
   *
   * @param {Object} body The order details.
   *
   * @returns {Promise}
   */
  placeNewOrder = async (body) =>
    await this.post(`${this.path}/order/placeneworder`, body);

  /**
   * Saves a draft order.
   *
   * @param {Object} body The draft order details.
   *
   * @returns {Promise}
   */
  saveDraftOrder = async (body) =>
    await this.post(`${this.path}/order/savedraftorder`, body);

  /**
   * Publishes a draft order by its ID.
   *
   * @param {Object} body    The draft order details.
   * @param {string} orderId The ID of the draft order.
   *
   * @returns {Promise}
   */
  publishDraftOrder = async (body, orderId) =>
    await this.put(
      `${this.path}/order/placedraftorder`,
      body,
      `?orderId=${orderId}`
    );

  /**
   * Edits an existing draft order by its ID.
   *
   * @param {Object} body    The updated draft order details.
   * @param {string} orderId The ID of the draft order.
   *
   * @returns {Promise}
   */
  editDraftOrder = async (body, orderId) =>
    await this.put(`${this.path}/order`, body, `?orderId=${orderId}`);

  /**
   * Cancels an order by its ID.
   *
   * @param {string} orderId The ID of the order to cancel.
   *
   * @returns {Promise}
   */
  cancelOrder = async (orderId) =>
    await this.patch(
      `${this.path}/order/cancelorder`,
      {},
      `?orderId=${orderId}`
    );

    /**
 * Restart WhatsApp notification for an order.
 *
 * @param {string} orderId The ID of the order to restart notification for.
 * @returns {Promise}
 */
    restartWhatsAppNotification = async (orderId) =>
      await this.patch(
        "notification-mgmt/sender/whatsapp/restart",   
        {},
        `?orderId=${orderId}`
      );

    

  /**
   * Deletes an order by its ID.
   *
   * @param {string} orderId The ID of the order to delete.
   *
   * @returns {Promise}
   */
  deleteOrder = async (orderId) =>
    await this.delete(`${this.path}/order/deleteorder`, `?orderId=${orderId}`);

  /**
   * Fetches order-related goods category data.
   *
   * @returns {Promise}
   */
  getGoodsCategory = async () => await this.get("order-mgmt/ordercatagory");

  /**
   * Fetches preferred vehicle type for the orders.
   *
   * @returns {Promise}
   */
  getPreferredVehicleType = async () =>
    await this.get("order-mgmt/vehicletype");

  /**
   * Fetches available order sizes.
   *
   * @returns {Promise}
   */
  getSize = async () => await this.get("order-mgmt/ordersize");

  /**
   * Fetches the dashboard statistics.
   *
   * @param {string} todayDate The date of the current day in UTC.
   *
   * @returns {Promise}
   */
  getDashboardStatistics = async (todayDate) =>
    await this.get(`${this.path}/dashboard`, `?today=${todayDate}`);
  

  /**
   * Fetches the dashboard order chart status count.
   *
   * @param {object} filterDates        Chart filter dates.
   * @param {string} [filterDates.from] Chart from date in UTC.
   * @param {string} [filterDates.to]   Chart to date in UTC.
   *
   * @returns {Promise}
   */
  getOrderStatusCount = async ({ from, to }) => {
    const query = !from || !to ? "" : `?from=${from}&to=${to}`;
    return await this.get(`${this.path}/order/statuscounts`, query);
  };


  
}


